import React from 'react'

import card1 from './asc.gif'
import card2 from './pas.gif'
import card4 from './icon/card3.gif'
import card3 from './gifla.gif'
import { Grid } from '@mui/material'


function About() {
  return (
    <div className='centeritall'>
      


        <div class="about-aztec-content">
             <Grid container spacing={3}>

             <Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>
             <div class="aztec-subsection">
             <div data-aos="fade-right">  <img src={card1} alt="Company Backed" /></div>
                  
             <a href='https://quickswap.exchange/#/swap?currency0=ETH&currency1=0xe5087395862a208071A7909687a6c4Fe30458F1e&swapIndex=2'>  <button className="learn-more-btn">TOKENS!</button></a> 
             <br/> <br/>
                    <p class="aztec-text">Buy Aztec Tokens as an investment, or to play exciting games we have developed. Sell Aztec Tokens for profit anytime!</p>
                </div>
             </Grid>


             <Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>
             <div class="aztec-subsection">
             <div data-aos="fade-right">       <img src={card2} alt="Company Backed"/></div>
         
               
        <a href='https://games.azteccrypto.xyz/'>  <button className="learn-more-btn">GAMES!</button></a> 
                    <br/> <br/>
                    <p class="aztec-text">Use your Aztec Tokens and PAY-TO-PLAY in our exciting gaming ecosystem, where you can win tons of jackpots!</p>
                </div>
             </Grid>
          
             <Grid item md={12} lg={12} xs={12} sm={12} className='centeritallgrid'>
                <div class="aztec-subsection">
                <div data-aos="fade-right">    <img src={card3} alt="Company Backed"/></div>
            
                <a href='https://staking.azteccrypto.xyz/'>  <button className="learn-more-btn">STAKING!</button></a>
                <br/> <br/>
                    <p class="aztec-text">USE OUR STAKING DAPP TO EARN HUGE RETURNS. Unlock the value of your Aztec Tokens, and sell them back to our liquidity pool. Cash in anytime on our secure platform.</p>
                </div></Grid>
           
          
             </Grid>
            
             
         
            </div>
       </div>
  )
}

export default About