import React from 'react'
import info1 from './image/info1.png'
import info2 from './image/info2.png'
import info3 from './image/info3.png'
import { Grid } from '@mui/material'
import ta1 from './image/ta1.png'
import ta2 from './image/ta2.png'
import ta3 from './image/ta3.png'
import gt1 from './image/gt1.png'
import gt2 from './image/gt2.png'
import { motion } from 'framer-motion';
import gt3 from './image/gt3.png'
import info from './image/packs.png'
function Info() {
  return (
    <div className='container'>
  <br/>  
  <img src={info}  style={{width:"100%"}}/>
        <br/>    <br/>
    <div className='centeritall'>
    <a href="https://mobilegames.azteccrypto.xyz/">
      <motion.button
        className="learn-more-btn"
        animate={{
          x: [0, -5, 5, -5, 5, 0], // Shake animation sequence
        }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        Play Games!
      </motion.button>
    </a>
    </div> <br/>   <br/> 
<h2 className="tophead_info">Before we dive in, Lets learn the</h2>
<h1 className="main-title_info">BASICS</h1>
<br/>
<div className='centeritall'>
<img src={info1}  style={{width:"80px"}}/>
<h2 className="info-title">Limited Token Supply!</h2>
<p className='info-title_under'>Our crypto tokens have a limited supply, ensuring their value remains high. These tokens are the currency used to participate in our thrilling games, where players have the opportunity to win jackpots in the form of tokens. This unique token utility model drives demand and adoption, creating a sustainable ecosystem. Join us in this groundbreaking venture and capitalize on our limited token supply, unlocking exciting potential for growth.</p>
</div>
<br/>
<div className='centeritall'>
<img src={info2}  style={{width:"80px"}}/>
<h2 className="info-title">Thrilling Crypto Games!</h2>
<p className='info-title_under'>

Our games are the driving force behind the success of our crypto tokens. As we continue to develop and release new and exciting games, we expect a growing number of players to be attracted to our platform. This increased user base will drive the demand for our tokens, making them more valuable over time. Our dedicated team is committed to creating captivating gaming experiences that will draw in and retain players, ensuring the long-term success of our project. We appreciate your support and invite you to join us on this thrilling journey.
</p>
</div> 
<br/>
<div className='centeritall'>
<img src={info3}  style={{width:"80px"}}/>
<h2 className="info-title">Benefits Of Aztec Crypto!</h2>
<p className='info-title_under'>
Our Tokens Have Many Benefits. Firstly We Have Low Fee's. Secondly We have Games 
Specifically Made For Our Tokens Making tokens used for more than just an investment option. Third, a 
portion of our gaming profits will go directly into the value of our tokens in efforts to increase our market cap, 
and your investment. Fourth, we have our own staking dapp made for our tokens. Our staking dapp is 
important because it allows investors to lock up liquidity and tokens, in return you will get a fair interest 
reward.

</p>
</div> 
<br/>   <br/>
<h2 className="tophead_info">Okay, what are the charges?</h2>
<h1 className="main-title_infot">Transaction Fees</h1>
<br/>
<Grid container spacing={2}>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

           <img src={ta1}  style={{width:"80px"}}/>
           <br/><br/>
<h2 className="info-title">2% Total Transaction Fee</h2>
<p className='info-title_under'>
There is a two percent tax on
every Purchase and Sell. As
well as, every transfer of
tokens not related to our
project. (Our Crypto Games
Don’t Charge the 2% fee
when transferring tokens to
and from)

</p>


           </Grid>
           <Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>



           <img src={ta2}  style={{width:"70px"}}/>
           <br/><br/>
<h2 className="info-title">1% for our Team</h2>
<p className='info-title_under'>
From the two percent, 1% will go to our team. This helps us keep operations running for our tokens.

</p>


           </Grid>
           <Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>



<img src={ta3}  style={{width:"80px"}}/>
<br/><br/>
<h2 className="info-title">1% for Rewards Staking Program</h2>
<p className='info-title_under'>
From the two percent, the last 1% will go to our staking rewards program. This will allow holders to successfully stake and earn more tokens.



</p>


</Grid>
</Grid>
<br/>   <br/>
<h2 className="tophead_info">Okay I get it now, how do I start?</h2>
<h1 className="main-title_infot">GET STARTED!</h1>
<br/>
<br/>
<Grid container spacing={2}>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

           <img src={gt1}  style={{width:"80px"}}/>
           <br/><br/>
<h2 className="info-title">Buying Polygon (MATIC)</h2>
<p className='info-title_under'>
Your first step is to create a crypto wallet, preferably from <a href='https://metamask.io/' target='_blank'>MetaMask</a> , and then purchase polygon inside your wallet from a major exchange such as <a href='https://www.coinbase.com/price/polygon?utm_campaign=rt_i_m_w_m_acq_ugc_soc_0_asset&utm_source=ugc&utm_platform=iOS' target='_blank'>CoinBase</a> .

</p>


           </Grid>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>



           <img src={gt2}  style={{width:"70px"}}/>
           <br/><br/>
<h2 className="info-title">Connect Your Wallet</h2>
<p className='info-title_under'>
Your next step is to come to our website and go to the <a href='https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xe5087395862a208071A7909687a6c4Fe30458F1e&swapIndex=1' target='_blank'>buy/sell</a>   page, then you must sign any disclosures.

</p>


           </Grid>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>



<img src={gt3}  style={{width:"80px"}}/>
<br/><br/>
<h2 className="info-title">Trade your Polygon (Matic) for Aztec Cryptocurrency (Aztec Tokens) </h2>
<p className='info-title_under'>
You last step is to input the correct amount of Matic you would like to swap for Aztec Tokens, and then adjust the max slippage on the trade slip. *It is important to adjust the max slippage before initiating the trade, please adjust the max slippage to around 30% if need be.



</p>


</Grid>
</Grid>



          
            

           




    </div>
  )
}

export default Info