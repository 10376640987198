import React from "react";
import { useMediaQuery } from "@mui/material";
import Navbars from "../Navbar/Navbars";
import MNavbars from "../Navbar/MobileNavbar";

import Welcome from "../component/Welcome/Welcome";
import WelcomeMobile from "../component/Welcome/WelcomeMobile";
import Footer from "../Footer/Footer";
import Divider from '@mui/material/Divider';
import MobileFooter from "../Footer/FooterMobile";


function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
      <Navbars/>
      <Welcome/>
      <Divider  style={{color:"#fff"     
    , height:'1px'
    , backgroundColor:"#fff"
    }}/>
    <br/>
      <Footer/>
        </div>
      )}
      {tabletBreakpoint && (
        <div>
           <Navbars/>
           <Welcome/>
           <Divider  style={{color:"#fff"     
    , height:'1px'
    , backgroundColor:"#fff"
    }}/>
    <br/>
           <MobileFooter/>
        </div>
      )}
      {mobileBreakpoint && (
        <div>
           <MNavbars/>
           <WelcomeMobile/><Divider  style={{color:"#fff"     
    , height:'1px'
    , backgroundColor:"#fff"
    }}/>
    <br/>
           <MobileFooter/>
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
